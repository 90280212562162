import React from 'react'
import styled from 'styled-components'
import { CompanyInfo } from '../constants'

const Wrapper = styled.article`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto 0 auto;

  .extra-wrapper {
    padding: 0 3rem 3rem 3rem;
  }
`

const ExtraServicesPage = () => (
  <Wrapper>
    <div className="extra-wrapper">
      <h1>Дополнительные услуги</h1>

      <p>
        Прайс на дополнительные услуги можно скачать по{' '}
        <a href={CompanyInfo.priceExtra} target="_blank">
          ссылке
        </a>
        .
      </p>
    </div>
  </Wrapper>
)

export default ExtraServicesPage
